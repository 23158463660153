<template>
  <div v-if="loaded">
    <div v-if="errormessage">
      {{ errormessage }}
    </div>
    <div v-else class="page-body">
      <div class="container-xl inv-size">
        <div class="card card-lg">
          <div class="card-body">
            <div class="row">
              <div class="container image-row mb-1">
                <div
                  v-for="siteLogoDTO in displaySiteLogoDTOs"
                  v-bind:key="siteLogoDTO.rowID"
                >
                  <img
                    :src="siteLogoDTO.siteImageName"
                    class="card"
                    style="padding: 2px; border: solid 1px #e7eaef"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 pb-3 pt-3 mb-1">
                <div class="card invoice-border">
                  <div class="card-body">
                    <div class="pt-1">
                      <h2 class="card-title font-weight-bold">
                        This is your Invoice
                      </h2>
                    </div>
                    <p>
                      <b style="color: red"
                        >Please note that our remittance address has changed.
                        Please send all payments to the following address:</b
                      >
                    </p>
                    <div>
                      MediaBrains-WF<br />
                      PO Box 931101<br />
                      Atlanta, GA 31193-1101
                    </div>
                    <div class="pt-2 pb-1" style="font-weight: bold">
                      Please print this invoice and include it with your
                      payment.<br />
                      Make check payable to MediaBrains Inc. and include the
                      Invoice Number on your check.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row inv-mobile">
              <div class="col-md-6 adv-info">
                <p class="h3">Advertiser Information:</p>
                <address v-html="lblAdvertiserInfo"></address>
              </div>
              <div class="col-md-6 text-end mb-4 inv-mobile">
                <h2 style="color: black"><b>Invoice</b></h2>
                <div class="text-end">
                  <table
                    cellpadding="2"
                    cellspacing="2"
                    border="0"
                    style="
                      float: right;
                      border-right: #d4d0c8 1px solid;
                      border-top: #d4d0c8 1px solid;
                      border-left: #d4d0c8 1px solid;
                      border-bottom: #d4d0c8 1px solid;
                    "
                  >
                    <tr>
                      <td bgcolor="#f1f1f5" align="center" width="33%">Date</td>
                      <td bgcolor="#f1f1f5" align="center" width="33%">
                        Invoice Number
                      </td>
                      <td bgcolor="#f1f1f5" align="center" width="33%">Terms</td>
                    </tr>
                    <tr>
                      <td align="center">{{ lblInvoiceDate }}</td>
                      <td align="center">{{ lblInvoiceID }}</td>
                      <td align="center">Due on receipt</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="row pt-2 inv-mobile">
              <div class="table-responsive">
                <table class="table table-transparent table-responsive">
                  <thead>
                    <tr>
                      <th>Placement</th>
                      <th class="text-center" style="width: 26%">Description</th>
                      <th class="text-center text-nowrap">Unit</th>
                      <th class="text-center text-nowrap" style="">
                        <span class="d-none d-sm-block">Amount Due</span>
                        <span class="d-block d-sm-none">Amount</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="invoiceItemDTO in displayInvoiceItemsDTOs"
                      v-bind:key="invoiceItemDTO.rowID"
                    >
                      <td class="text-left">
                        <p class="text mb-1">{{ invoiceItemDTO.itemName }}</p>
                        <div  v-if="invoiceItemDTO.itemName == 'Directory Listing Program'">
                          <div class="">
                          </div>
                          <div class="text-muted small indent">
                            {{ invoiceItemDTO.sitePlacement }}
                          </div>
                          <div class="text-muted small text-nowrap indent">
                            Intent Data Access
                          </div>
                          <div class="text-muted small text-nowrap indent">
                            MediaBrains Advertiser Center
                          </div>
                          <div class="text-muted small text-nowrap indent">
                            Professional Listing Setup
                          </div>
                        </div>
                      </td>
                      <td class="text-left">
                        {{ invoiceItemDTO.description }}
                      </td>
                      <td class="text-center text-nowrap">
                        {{ invoiceItemDTO.unitText }}
                      </td>
                      <td class="text-end align-middle">
                        <span class="">
                          {{ invoiceItemDTO.amount }}
                        </span>

                        <div class="ms-2 mt-1 text-right text-muted small italic text-end" v-if="invoiceItemDTO.itemName == 'Directory Listing Program'">
                          <div class="">
                          </div>
                          <div class="">
                            Included
                          </div>
                          <div class="">
                            Included
                          </div>
                          <div class="">
                            Included
                          </div>
                          <div class="">
                            Included
                          </div>
                        </div>

                      </td>
                    </tr>
                    <tr class="bottomborder">
                      <td colspan="5" class="font-weight-bold text-end">
                        <span style="padding-right: 5px">Total Amount Due</span>
                        <span style="padding-right: 2px">{{
                          this.money(this.totalamount)
                        }}</span>
                      </td>
                    </tr>
                    <tr class="bottomborder" v-if="this.specialterms">
                      <td colspan="5" class="font-weight-bold text-start">
                        <div class="alert alert-info" role="alert">
                          <div class="d-flex justify-content-start">
                            <div>
                              <img src="@/assets/info.svg" />
                            </div>
                            <div>&nbsp;{{ this.specialterms }}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col-md-12">
                <div>
                  If you need any assistance, please call our Online Marketing
                  Specialists at (866) 627-2467, or email your request to
                  <a href="mailto:advertisersupport@mediabrains.com"
                    >advertisersupport@mediabrains.com</a
                  >.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center pt-2" v-else>
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      ></span>
      Loading
  </div>
</template>

<style scoped>
.conditions {
  text-decoration: underline;
}
.paidgraphc {
  z-index: 999;
  left: 36%;
  position: absolute;
  top: 154px;
}
.container {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px 5px 5px;
}
.bottomborder {
  border-bottom-color: #ffffff;
}
.invoicebox {
  border: #000000 1px solid;
}
.card-title {
  display: block;
  margin: 0 0 1rem;
  line-height: 1.5rem;
  font-size: 24px !important;
}
.invoice-border {
  border-color: #000000;
  border-radius: 0px;
}

.image-row {
  padding-left: 8px;
}
.adv-info {
  padding-top: 2px;
}
.row {
  padding-left: 5px;
}
.inv-size {
  max-width: 1400px;
}
.table thead th {
  font-weight: bold !important;
  color: #000000;
}

.non-mobile-nowrap {
  white-space: nowrap !important;
}

.indent {
  padding-left:20px !important;
}

.italic {
  font-style: italic;
}

@media (max-width: 420px) {
  .inv-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .text-nowrap {
    white-space: normal !important;
  }

  .text-center {
    text-align: left !important;
  }

  .table > :not(caption) > * > *,
  .markdown > table > :not(caption) > * > * {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .row {
    padding-left: 0px !important;
  }
}
</style>

<script>
import BillingService from "../../services/BillingService";

import TrackingService from "../../services/TrackingService";

export default {
  name: "PayByCC",
  components: {},
  data: () => {
    return {
      errormessage: "",
      formErrors: {},
      invoiceDTO: [],
      invoiceItemsDTOs: [],
      siteLogoDTOs: [],
      existingInvoice: [],
      existingInvoicesItems: [],
      existingSiteLogos: [],
      lblAdvertiserInfo: "",
      lblInvoiceDate: "",
      lblInvoiceID: "",
      totalamount: 0,
      invoiceid: "",
      incidentid: "",
      insertionorderid: "",
      loaded: false,
      ispaid: false,
      issoldout: false,
      isinactiveitem: false,
      installmentduration: 0,
      submittingPayment: false,
      paymentSuccess: false,
      paymentError: false,
      referencenumber: "",
      chargedamount: 0,
      confirmationMessage: "",
      transactionCompleteMessage: "",
      maxordertotal: 0,
      soldout: "",
      inactiveitem: "",
      specialterms: "",
      rows: 0,
      companyId: null,
      contactId: null,
      repId: null,
    };
  },
  methods: {
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },

    money(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(amount);
    },

    validate(item) {
      if (item === "all" || item === "soldout") {
        if (this.issoldout) {
          this.$set(this.formErrors, "soldout", true);
        } else {
          this.$set(this.formErrors, "soldout", false);
        }
      }
      if (item === "all" || item === "inactiveitem") {
        if (this.isinactiveitem) {
          this.$set(this.formErrors, "inactiveitem", true);
        } else {
          this.$set(this.formErrors, "inactiveitem", false);
        }
      }
    },

    hasFeaturedPlacement(itemname){
      return itemname.indexOf("Directory Listing Program") !== -1
    },

    async LoadInvoice() {
      let invoiceFilter = {
        InvoiceID: this.invoiceid,
        SecurityCheck: this.securitycheck,
      };

      let invoiceDTO = await BillingService.loadInvoice(invoiceFilter);

      // get total amount of invoice
      this.totalamount = invoiceDTO.salesAmount;
      this.invoiceid = invoiceDTO.invoiceID;
      this.ispaid = invoiceDTO.paid;
      this.companyId = invoiceDTO.companyId;
      this.contactId = invoiceDTO.contactID;
      this.repId = invoiceDTO.repID;
      this.specialterms = invoiceDTO.specialTerms;

      // invoice date is the current date for unpaid invoices or confirmdate for confirmed
      this.lblInvoiceDate = this.getFormattedDate(invoiceDTO.invoiceCreateDate);
      this.lblInvoiceID = "M" + invoiceDTO.invoiceID;

      //build address information
      let strCompleteAddress = invoiceDTO.billingAddress;

      if (invoiceDTO.billingAddress2 != "") {
        strCompleteAddress =
          strCompleteAddress + "<br> " + invoiceDTO.billingAddress2;
      }
      if (invoiceDTO.billingCity != "") {
        strCompleteAddress =
          strCompleteAddress + "<br>" + invoiceDTO.billingCity;
      }
      if (invoiceDTO.billingState != "") {
        if (invoiceDTO.billingCity != "")
          strCompleteAddress =
            strCompleteAddress + ", " + invoiceDTO.billingState;
        else
          strCompleteAddress =
            strCompleteAddress + "<br>" + invoiceDTO.billingState;
      }
      if (invoiceDTO.billingPostalCode != "") {
        strCompleteAddress =
          strCompleteAddress + " " + invoiceDTO.billingPostalCode;
      }
      if (invoiceDTO.billingCountry != "") {
        strCompleteAddress =
          strCompleteAddress + "<br>" + invoiceDTO.billingCountry;
      }

      //build billing attn name
      let strBillingAttn = invoiceDTO.billingAttn;

      //build company information
      let strCompleteAdvertiserInfo = invoiceDTO.companyName;

      if (strBillingAttn != "")
        strCompleteAdvertiserInfo += "<br>Attn: " + strBillingAttn;
      if (strCompleteAddress != "")
        strCompleteAdvertiserInfo += "<br>" + strCompleteAddress;

      this.lblAdvertiserInfo = strCompleteAdvertiserInfo;

      // save invoiceDTO
      this.existingInvoice = invoiceDTO;
    },

    async LoadInvoiceItems() {
      let invoiceFilter = {
        InvoiceID: this.invoiceid,
        SecurityCheck: this.securitycheck,
      };

      let invoiceItemsDTOs = await BillingService.loadInvoiceItems(
        invoiceFilter
      );

      // interate through press releases
      invoiceItemsDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // format money
        n.amount = this.money(n.amount);

        // build rowid
        n.rowID = this.rows;
      });

      // save invoiceItemsDTOs
      this.existingInvoicesItems = invoiceItemsDTOs;
    },

    async LoadSiteLogos() {
      let invoiceFilter = {
        InvoiceID: this.invoiceid,
        SecurityCheck: this.securitycheck,
      };

      let siteLogoDTOs = await BillingService.loadSiteLogos(invoiceFilter);

      // interate through press releases
      siteLogoDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // format site image
        n.siteImageName = process.env.VUE_APP_SITE_LOGO_URL + n.siteImageName;

        // build rowid
        n.rowID = this.rows;
      });

      // save siteLogoDTOs
      this.existingSiteLogos = siteLogoDTOs;
    },

    async Checkout() {
      let invoiceFilter = {
        InvoiceID: this.invoiceid,
      };

      // check if invoice item is sold out
      this.soldout = await BillingService.checkSoldOutItem(invoiceFilter);

      if (this.soldout) {
        this.issoldout = true;
        this.$set(this.formErrors, "soldout", true);
        this.postSuccess = false;
        this.postError = true;
        this.filePosted = false;
        return;
      }

      // check if invoice item is active
      this.inactiveitem = await BillingService.checkInActiveItem(invoiceFilter);

      if (this.inactiveitem) {
        this.isinactiveitem = true;
        this.$set(this.formErrors, "inactiveitem", true);
        this.postSuccess = false;
        this.postError = true;
        this.filePosted = false;
        return;
      }

      let CheckoutDTO = {
        paymenttype: JSON.stringify("ck"),
        totalamount: JSON.stringify(this.totalamount),
        invoiceid: JSON.stringify(this.invoiceid),
        incidentid: JSON.stringify(this.incidentid),
        insertionorderid: JSON.stringify(this.insertionorderid),
      };

      this.submittingPayment = true;

      try {
        let paymentResult = await BillingService.checkout(CheckoutDTO);
        if (paymentResult.success) {
          this.paymentSuccess = true;
          this.chargedamount = paymentResult.chargedAmount;
          this.cardlastfour = paymentResult.lastFour;
          this.referencenumber = paymentResult.referenceNumber;
          this.transactionCompleteMessage =
            paymentResult.transactionCompleteMessage;
        } else {
          this.paymentSuccess = false;
          this.submittingPayment = false;
          this.paymentError = true;
        }
      } catch (e) {
        console.log(e);
        this.paymentSuccess = false;
        this.submittingPayment = false;
        this.paymentError = true;
      }
    },

    async ProcessUrl() {
      // iid
      let iid = this.$route.query.iid;

      // invoiceid
      this.invoiceid = this.$route.query.invoiceid;

      // no invoiceid passed, create one now
      if (!this.invoiceid) {

        // set invoiceFilter to check ioid
        let invoiceFilter = {
         InsertionOrderID: iid,
         CreateInvoice: true
        };

        iid = await BillingService.getIIDByIOID(invoiceFilter);

        // get InvoiceID
        this.invoiceid = iid
         .toString()
         .substring(0, iid.toString().length - 3);

      }

      // check if invoiceid found
      if (this.invoiceid) {
        let invoiceFilter = {
          InvoiceID: this.invoiceid,
        };

        // check if invoice paid
        let ispaid = await BillingService.isPaidInvoice(invoiceFilter);

        if (ispaid) {
          // goto to invdtl as invoice already paid
          this.$router.push("/billing/invdtl?ioid=" + iid + "&ic=1");
        } else {
          // check if inventory still available
          let isinventoryavailable = await BillingService.isInventoryAvailable(
            invoiceFilter
          );
          // load the invoice
          this.LoadInvoice();
          this.LoadInvoiceItems();
          this.LoadSiteLogos();
          this.loaded = true;
          // call pay by check
          this.Checkout();
        }
      }
    },
  },

  computed: {
    displayInvoiceItemsDTOs() {
      return this.existingInvoicesItems;
    },

    displaySiteLogoDTOs() {
      return this.existingSiteLogos;
    },
  },

  async mounted() {
    await this.ProcessUrl();

    TrackingService.track("viewed invoice pay by check", {
      companyId: this.companyId,
      contactId: this.contactId,
      repId: this.repId,
    });
  },
};
</script>
